import "./App.css";
import Landing from "./Components/Landing/Landing";

function App() {
  return (
    <div style={{width: "100%", margin: "auto"}}>
      <Landing />
    </div>
  );
}

export default App;
