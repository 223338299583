import React from 'react';
import "./styles/sidebar.css";

function Sidebar() {
    return (
        <div className='sidebar-parent'> 
            <div className="sidebar">
                <div className="text-box">
                    <ul>
                        <li>
                            <h3>Get Matched</h3>
                            <p>with a short questionnaire to find the perfect vendor.</p>
                        </li>
                        <li>
                            <h3>Real Talk</h3>
                            <p>with verified reviews, endorsements, and vendors.</p>
                        </li>
                        <li>
                            <h3>Book meetings</h3>
                            <p>with vendors inside the app. No payment required.</p>
                        </li>
                    </ul>
                    <div className="help-section">
                        <p>Need some help finding your perfect placement?</p>
                        <button>Find Match</button>
                    </div>
                </div>
            </div>
            <div className="addfirm-sidebar">
                    <p>Add your firm to the list</p>
                    <button>Add to list</button>
            </div>
        </div>
    );
}

export default Sidebar;
