import React, { useState } from "react";
import "./nav.css";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="parent">
      <div className="left">
        <div className="logo">
          <a href="/">
            <h4>PlacementList</h4>
          </a>
        </div>
      </div>
      <div className="right">
        <div className="nav-item">
          <a href="/login">
            <h4>Log in</h4>
          </a>
        </div>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        ☰
      </div>
      {isOpen && (
        <div className="mobile-menu">
          <div className="nav-item">
            <a href="/login">
              <h4>Log in</h4>
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default NavBar;
