import React from "react";
import Navbar from "../Navbar/Navbar";
import Hero from "./Hero";
import "../../App.css"
import List from "./List";
import Sidebar from "./Sidebar";

function Landing() {
  return (
    <div>
      <Navbar />
      <div className="container">
        <Hero />
        <div className="main-content">
          <List />
          <Sidebar />
        </div>
      </div>
    </div>
  );
}

export default Landing;
 