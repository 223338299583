import React from "react";
import { ReactTyped } from "react-typed";
import "./styles/hero.css"

function Hero() {
  return (
    <div className="hero-container">
      <div className="text-div">
        <h1 className="hero-title">
          Find a{" "}
          <br className="tablet-blocks" />
          <span className="typed-box">
            <ReactTyped
               strings={[
               "Placement Agency",
               "Staffing Agency",
               "Head Hunter",
               "Recruiting Firm",
               "Executive Recruiter",
               "Search Firm",
              ]}
              typeSpeed={55}
              backSpeed={20}
              startDelay={150}
              smartBackspace={true}
              backDelay={4000}
              loop
            />
          </span>{" "}
          <br />
          <br className="blocks"/>
          with Placement List
        </h1>
        <h2 className="hero-subtitle">
          <span style={{fontWeight: "700"}}>Read reviews on hundreds of service providers</span> on the only
          dedicated staffing review site. Packed with verified client reviews and
          vendor information that you cannot get anywhere else.
        </h2>
        </div>
    </div>
  );
}

export default Hero;
